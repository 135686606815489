define("ember-format-json/components/format-json", ["exports", "@ember/component", "ember-format-json/templates/components/format-json", "json-formatter-js"], function (_exports, _component, _formatJson, _jsonFormatterJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _formatJson.default,
    open: 0,
    value: '',
    didRender() {
      const formatter = new _jsonFormatterJs.default(this.value, this.open);
      this.element.innerHTML = '';
      this.element.append(formatter.render());
    }
  });
  _exports.default = _default;
});
define("ember-code-snippet/helpers/get-code-snippet", ["exports", "@ember/component/helper", "ember-code-snippet"], function (_exports, _helper, _emberCodeSnippet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _helper.helper)(function (_ref, _ref2) {
    let [name] = _ref;
    let {
      unindent = true
    } = _ref2;
    return (0, _emberCodeSnippet.getCodeSnippet)(name, unindent);
  });
  _exports.default = _default;
});
define("ember-svg-jar/inlined/guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icons/guide</title><g fill-rule=\"evenodd\"><g><path d=\"M11 18.08c-.67.19-1.336.403-2 .642v28.927c10-3.306 19.556-.136 24 3.351 2.667-2.615 14-6.657 24-3.351V18.722a30.156 30.156 0 00-2-.642v27.3c-6.453-2.92-13.787-2.047-22 2.62V34.187 48c-8.8-4.667-16.133-5.54-22-2.62v-27.3z\"/><path d=\"M34 17.44c5.976-3.001 11.976-3.234 18-.698v24.976C46.762 39.689 40.762 40.45 34 44V17.44zM32 17.44c-5.976-3.001-11.976-3.234-18-.698v24.976C19.238 39.689 25.238 40.45 32 44V17.44z\"/></g></g>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/git-sha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>git-sha</title><path d=\"M18.322 28C19.696 21.71 25.298 17 32 17c6.702 0 12.304 4.71 13.678 11H58v6H45.678C44.304 40.29 38.702 45 32 45c-6.702 0-12.304-4.71-13.678-11H6v-6h12.322zM32 39a8 8 0 100-16 8 8 0 000 16z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
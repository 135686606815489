define("ember-modal-dialog/templates/components/overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="emd-debug"
    tabindex='-1'
    data-emd-overlay
    {{did-insert this.didInsert}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "TJNMjrsn",
    "block": "[[[11,0],[24,0,\"emd-debug\"],[24,\"tabindex\",\"-1\"],[24,\"data-emd-overlay\",\"\"],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"willDestroyNode\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/overlay.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group</title><g fill-rule=\"evenodd\"><path d=\"M3.557 34.011l8.842 8.843-9.733 2.808A1.5 1.5 0 01.75 43.744l2.808-9.733zM14.364 40.889l-8.842-8.842L31.067 6.502l8.842 8.842zM41.874 13.379l-8.842-8.843 2.456-2.456a6.253 6.253 0 018.843 8.843l-2.457 2.456z\"/></g>",
    "attrs": {
      "width": "47",
      "height": "46",
      "viewBox": "0 0 47 46",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
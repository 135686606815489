define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasOverlay}}
    <LiquidWormhole @stack='modal-overlay' @class='liquid-dialog-container'>
      <EmberModalDialog::Overlay
        class={{this.overlayClassNamesString}}
        @onClickOverlay={{this.onClickOverlay}}
      />
    </LiquidWormhole>
  {{/if}}
  <LiquidTether
    @class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    @stack={{this.stack}}
    @value={{this.value}}
    ...attributes
  >
    {{yield}}
  </LiquidTether>
  */
  {
    "id": "UDk9qaMZ",
    "block": "[[[41,[30,0,[\"hasOverlay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@stack\",\"@class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[30,0,[\"onClickOverlay\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[8,[39,3],[[17,1]],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\",\"@stack\",\"@value\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"tetherTarget\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"offset\"]],[30,0,[\"targetOffset\"]],[30,0,[\"constraints\"]],[30,0,[\"stack\"]],[30,0,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"liquid-wormhole\",\"ember-modal-dialog/overlay\",\"liquid-tether\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/liquid-tether-dialog.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
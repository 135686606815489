define("ember-svg-jar/inlined/skip-forward-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M65.53 23.38l6.69-4.66A37.37 37.37 0 0039.06 7.17h-.24l-1.28.24-.63.13-.91.24L35 8l-.48.14c-.46.13-.92.27-1.37.42h-.1a37.55 37.55 0 00-17.33 12.15c-.31.39-.61.79-.9 1.19l-.26.35c-.21.3-.42.6-.63.91l-.47.7-.37.6c-.22.35-.43.71-.64 1.07l-.14.26A37.53 37.53 0 008 49.66c0 .14 0 .27.06.41.06.37.13.73.2 1.1.07.37.11.55.17.82.06.27.11.45.16.67.1.41.2.82.31 1.22l.1.26A37.33 37.33 0 0038.24 80.7c.633.113 1.263.21 1.89.29h.14c.58.07 1.17.13 1.75.18h.09c19.029 1.372 36.03-11.825 39.42-30.6a3.34 3.34 0 116.57 1.18 43.82 43.82 0 01-17.94 28.14c-.08.11-.16.11-.22.2a43.65 43.65 0 01-25 7.89c-2.632 0-5.26-.235-7.85-.7a47.583 47.583 0 01-2.2-.45l-.25-.06C13.67 81.75-.572 62.275 1 40.77v-.08c0-.64.12-1.28.19-1.92v-.32c.093-.74.207-1.48.34-2.22.26-1.447.593-2.867 1-4.26C7.81 13.377 24.606.407 43.93 0a44.43 44.43 0 018.66.69 43.62 43.62 0 0125.16 14.18l7.23-5c2-1.4 3.69-.54 3.74 1.91l.42 20.49c0 2.45-1.77 3.72-4.05 2.82L66 27.55c-2.26-.9-2.48-2.77-.47-4.17z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "90",
      "height": "88",
      "viewBox": "0 0 90 88",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
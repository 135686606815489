define("ember-svg-jar/inlined/git-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>git-tag</title><path d=\"M10.76 30.286l.09-17.457a4 4 0 013.979-3.98l17.457-.089a6 6 0 014.273 1.758L58.953 32.91a2 2 0 010 2.829L37.74 56.953a2 2 0 01-2.829 0L12.518 34.559a6 6 0 01-1.758-4.273zm14.85-6.676c1.953-1.952 1.945-5.126-.017-7.088-1.962-1.962-5.135-1.97-7.088-.017-1.952 1.953-1.945 5.126.017 7.088 1.962 1.962 5.136 1.97 7.088.017z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
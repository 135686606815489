define("ember-svg-jar/inlined/angle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 320 512",
      "class": "svg-inline--fa fa-angle-up fa-w-10 fa-3x"
    }
  };
  _exports.default = _default;
});
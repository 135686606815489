define("ember-svg-jar/inlined/api-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icons/class</title><path d=\"M13.026 30.176a6.4 6.4 0 001.128-.13 4.337 4.337 0 001.345-.5c.434-.245.81-.57 1.128-.975.318-.405.478-.926.478-1.562v-8.937c0-1.013.202-1.895.607-2.647.405-.752.897-1.374 1.475-1.865a5.96 5.96 0 011.822-1.085c.636-.231 1.186-.347 1.649-.347h3.948v3.905H24.22c-.55 0-.984.094-1.302.282a2.004 2.004 0 00-.738.737 2.618 2.618 0 00-.325.998 8.58 8.58 0 00-.065 1.02v8.46c0 .867-.174 1.59-.52 2.168-.348.579-.76 1.034-1.237 1.367a5.42 5.42 0 01-1.475.738c-.506.159-.933.253-1.28.282v.086c.347.03.774.102 1.28.217.506.116.998.34 1.475.673.477.332.89.81 1.236 1.431.347.622.521 1.454.521 2.495v8.2c0 .318.022.658.065 1.02.043.36.152.693.325.997.174.304.42.55.738.737.318.188.752.282 1.302.282h2.386v3.905h-3.948c-.463 0-1.013-.116-1.649-.347a5.96 5.96 0 01-1.822-1.085c-.578-.491-1.07-1.113-1.475-1.865s-.607-1.634-.607-2.647v-8.59c0-.694-.16-1.272-.478-1.735a3.65 3.65 0 00-1.128-1.085 4.455 4.455 0 00-1.345-.542c-.462-.101-.838-.152-1.128-.152v-3.904zm38.092 3.904c-.319 0-.709.05-1.172.152a5.217 5.217 0 00-1.345.499c-.434.231-.802.55-1.106.954-.304.405-.456.926-.456 1.562v8.937c0 1.013-.202 1.895-.607 2.647-.405.752-.897 1.374-1.475 1.865a5.96 5.96 0 01-1.822 1.085c-.636.231-1.186.347-1.649.347h-3.948v-3.905h2.386c.55 0 .984-.094 1.302-.282.318-.188.564-.433.738-.737.173-.304.282-.636.325-.998a8.58 8.58 0 00.065-1.02v-8.46c0-.867.174-1.59.52-2.168.348-.579.76-1.034 1.237-1.367a4.96 4.96 0 011.475-.716 11.98 11.98 0 011.28-.304v-.086a9.443 9.443 0 01-1.28-.217 4.22 4.22 0 01-1.475-.673c-.477-.332-.89-.81-1.236-1.431-.347-.622-.521-1.454-.521-2.495v-8.2a8.58 8.58 0 00-.065-1.02 2.618 2.618 0 00-.325-.997 2.004 2.004 0 00-.738-.737c-.318-.188-.752-.282-1.302-.282h-2.386v-3.905h3.948c.463 0 1.013.116 1.649.347a5.96 5.96 0 011.822 1.085c.578.491 1.07 1.113 1.475 1.865s.607 1.634.607 2.647v8.59c0 .694.152 1.272.456 1.735.304.463.672.824 1.106 1.085.434.26.882.44 1.345.542.463.101.853.152 1.172.152v3.904z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/skip-backward-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.47 23.38l-6.69-4.66A37.37 37.37 0 0150.94 7.17h.24l1.28.24.63.13.91.24L55 8l.48.14c.46.13.92.27 1.37.42h.1a37.55 37.55 0 0117.33 12.15c.31.39.61.79.9 1.19l.26.35c.21.3.42.6.63.91l.47.7.37.6c.22.35.43.71.64 1.07l.14.26A37.53 37.53 0 0182 49.66c0 .14 0 .27-.06.41-.06.37-.13.73-.2 1.1-.07.37-.11.55-.17.82-.06.27-.11.45-.16.67-.1.41-.2.82-.31 1.22l-.1.26A37.33 37.33 0 0151.76 80.7c-.633.113-1.263.21-1.89.29h-.14c-.58.07-1.17.13-1.75.18h-.09c-19.029 1.372-36.03-11.825-39.42-30.6a3.34 3.34 0 10-6.57 1.18 43.82 43.82 0 0017.94 28.14c.08.11.16.11.22.2a43.65 43.65 0 0025 7.89c2.632 0 5.26-.235 7.85-.7.74-.133 1.473-.283 2.2-.45l.25-.06c20.97-5.02 35.212-24.495 33.64-46v-.08c0-.64-.12-1.28-.19-1.92v-.32a42.53 42.53 0 00-.34-2.22 41.213 41.213 0 00-1-4.26C82.19 13.377 65.394.407 46.07 0a44.43 44.43 0 00-8.66.69 43.62 43.62 0 00-25.16 14.18l-7.23-5c-2-1.4-3.69-.54-3.74 1.91L.86 32.27c0 2.45 1.77 3.72 4.05 2.82L24 27.55c2.26-.9 2.48-2.77.47-4.17z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "90",
      "height": "88",
      "viewBox": "0 0 90 88",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>check</title><path fill-rule=\"nonzero\" d=\"M9 30l13.25 13.25L54 11.5l5.5 5.5-37.25 37.25L3.5 35.5z\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-modal-dialog/utils/config-utils", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clickHandlerDelay = clickHandlerDelay;
  _exports.getDestinationElementIdFromConfig = getDestinationElementIdFromConfig;
  _exports.isIOS = void 0;
  function getDestinationElementIdFromConfig(config) {
    // if (config.environment === 'test') {
    //   return 'ember-testing';
    // }
    let modalContainerId = config['ember-modal-dialog'] && config['ember-modal-dialog'].modalRootElementId;
    modalContainerId = modalContainerId || 'modal-overlays';
    return modalContainerId;
  }
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  _exports.isIOS = isIOS;
  function clickHandlerDelay(component) {
    let ENV = (0, _application.getOwner)(component).resolveRegistration('config:environment');
    if (ENV.environment === 'test') {
      return 0;
    }
    return 300;
  }
});
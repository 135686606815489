define("ember-svg-jar/inlined/stop-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M159.5 181.3h-137c-12 0-21.7-9.7-21.7-21.7v-137C.8 10.6 10.5.9 22.5.9h137c12 0 21.7 9.7 21.7 21.7v137c0 12-9.7 21.7-21.7 21.7zM22.5 12.9c-5.4 0-9.7 4.4-9.7 9.7v137c0 5.4 4.4 9.7 9.7 9.7h137c5.4 0 9.7-4.4 9.7-9.7v-137c0-5.4-4.4-9.7-9.7-9.7h-137z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "182",
      "height": "182",
      "viewBox": "0 0 182 182",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>search</title><path d=\"M23.997 40.742c-9.249 0-16.746-7.497-16.746-16.745 0-9.248 7.497-16.745 16.746-16.745 9.248 0 16.746 7.497 16.746 16.745a16.674 16.674 0 01-16.746 16.745zm38.885 16.93L43.534 38.32a23.59 23.59 0 004.65-14.328 24.092 24.092 0 10-39.67 18.475 24.092 24.092 0 0029.807 1.065l19.353 19.35a3.6 3.6 0 005.212 0 3.6 3.6 0 00-.004-5.211z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
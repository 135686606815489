define("ember-svg-jar/inlined/load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.996 2c-.879 0-1.735.058-2.574.152l.224 1.989c.781-.088 1.56-.141 2.35-.141 11.61 0 21 9.39 21 21s-9.39 21-21 21c-.785 0-1.56-.052-2.334-.139l-.223 1.989c.835.093 1.685.15 2.557.15 12.69 0 23-10.31 23-23s-10.31-23-23-23zm-7.592 1.309a22.826 22.826 0 00-4.636 2.236l1.066 1.691a20.834 20.834 0 014.232-2.04l-.662-1.887zM8.73 8.739a23.079 23.079 0 00-3.195 4.038l1.694 1.065a21.076 21.076 0 012.916-3.69L8.73 8.738zM24 13l-.004 19.21-5.627-5.626L16.955 28l8.041 8.04L33.037 28l-1.414-1.416-5.627 5.627L26 13h-2zM3.303 17.416a22.846 22.846 0 00-1.155 5.018l1.989.22a20.848 20.848 0 011.054-4.58l-1.888-.658zm.836 9.943l-1.989.221c.194 1.747.6 3.422 1.159 5.018l1.886-.66a20.795 20.795 0 01-1.056-4.579zm3.097 8.809l-1.691 1.066a23.078 23.078 0 003.197 4.037l1.414-1.414a21.095 21.095 0 01-2.92-3.689zm6.61 6.603l-1.065 1.694c1.446.908 3 1.66 4.639 2.232l.658-1.888a20.919 20.919 0 01-4.232-2.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});